import Section from "@/components/ui/section";
import React from "react";
import ProductCard from "./product-card";
import { m } from "framer-motion";
export default function ProductGrid({ products }) {
  return (
    <div className="grid auto-rows-auto lg:grid-cols-3 md:grid-cols-2 grid-cols-2 w-full lg:gap-10 md:gap-6 gap-y-6 gap-x-2 mt-12">
      {products?.map((row, key) => (
        <ProductCard product={row} key={"product" + key} />
      ))}
    </div>
  );
}
