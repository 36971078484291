import React from "react";
import Image from "next/image";
import Link from "next/link";
import { m } from "framer-motion";
import usePrice from "@/framework/product/use-price";
export default function ProductCard({ product }) {
  const { price, basePrice, discount } = usePrice({
    amount: product?.sellingPrice,
    baseAmount: product?.MRP,
    currencyCode: "INR",
  });
  return (
    <Link
      href={`/products/${product?.slug}`}
      className="transition-all duration-300 ease flex items-start justify-start flex-col relative overflow-hidden w-full "
    >
      {discount && (
        <span className="absolute z-10 top-3 right-2 text-darkBrown font-semibold bg-beige rounded-full px-6 py-1 md:py-1.5 leading-none text-xs transform">
          {discount}
        </span>
      )}

      {/* {product?.images?.data[0]?.attributes?.url && ( */}
      <m.div
        className="relative w-full lg:h-[30vw] md:h-[286px] h-[150px] overflow-hidden"
        whileHover={{ opacity: product?.images?.data[1] ? 0 : 100 }}
        transition={{ duration: 0.5 }}
      >
        <Image
          src={
            product?.images?.data[0]?.attributes?.url ??
            "/images/placeholder.svg"
          }
          fill
          alt={product?.images?.data[0]?.attributes?.alternativeText}
          className="object-cover object-center w-full h-full"
          sizes="(max-width: 768px) 400px, (max-width: 1200px) 400px, 400px"
        />
      </m.div>

      {product?.images?.data[1] && (
        <m.div
          className="absolute top-0 left-0 w-full lg:h-[30vw] h-[286px] overflow-hidden"
          initial={{ opacity: 0 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Image
            src={
              product?.images?.data[1]?.attributes?.url ??
              "/images/placeholder.svg"
            }
            fill
            alt={product?.images?.data[1]?.attributes?.alternativeText}
            className="object-cover object-center w-full h-full"
            sizes="(max-width: 768px) 400px, (max-width: 1200px) 400px, 400px"
          />
        </m.div>
      )}
      <div className="flex flex-col w-full gap-1 py-3">
        <div className="flex md:flex-row flex-col items-start justify-between md:gap-4 gap-1 w-full">
          <p className="text-brown text-base font-extrabold leading-tight">
            {product?.name}
          </p>
          <div className="flex flex-col">
            <span className="flex text-brown gap-2" id="sale-price">
              {price.toLocaleString()}
              {discount && !product?.stock == 0 && (
                <del className="text-[#A30A0A]" id="normal-price">
                  {basePrice?.toLocaleString()}
                </del>
              )}
            </span>
          </div>
          {/* {product?.category?.data && (
            <p className="absolute top-4 left-4 text-darkGrey text-sm font-normal bg-darkBeige rounded-full px-3 py-0.5 w-fit">
              {product?.category?.data?.attributes?.name}
            </p>
          )} */}
        </div>

        {/* <div className="flex flex-col">
          <span className="flex text-brown gap-4" id="sale-price">
            {price.toLocaleString()}
            {discount && !product?.stock == 0 && (
              <del className="text-[#A30A0A]" id="normal-price">
                {basePrice?.toLocaleString()}
              </del>
            )}
          </span>
        </div> */}
      </div>
    </Link>
  );
}
